@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.btn {
  padding: 12px 15px;
  font-size: 14px;
  background-color: #fff;
  color: $primary-color;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-width: initial;
  border-style: none;
  border-color: $primary-color;
  border-image: initial;
  outline: 0px;
  border-radius: 6px;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  @include breakpoint-up(md) {
    font-size: 16px;
    padding: 12px 20px;
  }
  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
  &:hover:not(:disabled) {
    background-color: $primary-light-color;
    color: #fff;
    transition: all 0.3s ease-in;
  }
  &:focus {
    outline: none;
  }
  .btnContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 26px;
    @include breakpoint-up(md) {
      width: 100%;
      max-height: 100%;
      height: 100%;
    }
  }
  .btnContentWithIcon {
    div + .btnChildren:not(:empty) {
      margin-left: 5px;
    }
    .btnChildren:not(:empty) + div {
      margin-left: 5px;
      display: flex;
    }
  }
}

.btnWithTopIcon {
  // max-width: 115px;
  min-height: 100px;
  overflow-x: hidden;
  // @include breakpoint-up(md) {
  //   max-width: initial;
  // }
  .btnContent {
    flex-direction: column-reverse;
    & div:empty + .topIcon {
      svg {
        max-height: 60px;
        @include breakpoint-up(md) {
          max-height: 75px;
        }
      }
    }
    .topIcon {
      width: 100%;
      min-width: 80px;
      svg {
        max-height: 50px;
        @include breakpoint-up(md) {
          max-height: 60px;
        }
      }
    }
    .btnChildren {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 12px;
      @include breakpoint-up(md) {
        font-size: 13px;
      }
    }
  }
}

.btnLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary {
  background-color: #fff;
  border: 1px solid $primary-color;
  box-sizing: border-box;
  box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 16px;
  color: $primary-color;
}

.outline {
  border: 1px solid $gray-light-color;
  border-radius: 6px;
}

.secondary {
  background-color: $primary-color;
  color: white;
  &:hover:not(:disabled) {
    background-color: rgba($primary-color, 0.85);
  }
}

.success {
  background-color: $success-color;
  color: white;
  &:hover:not(:disabled) {
    background-color: rgba($success-color, 0.85);
  }
}

.outlineSuccess {
  background-color: white;
  color: $success-color;
  border: 1px solid $success-color;
  &:hover:not(:disabled) {
    background-color: $success-color;
    color: white;
  }
}

.warning {
  background-color: $yellow-color;
  color: black;
  &:hover:not(:disabled) {
    background-color: darken($yellow-color, 0.7);
    color: black;
  }
  svg path {
    fill: $primary-color;
  }
}