@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.row {
  h1 {
    text-align: left;
  }
  align-items: center;
  .buttonsWrapper {
    text-align: right;
    @include breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    button {
      svg path {
        fill: $yellow-color;
      }
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
