.overlay {
  p,
  div {
    margin: 8px;
    padding-right: 10px;
  }
  .closeBtn {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    outline: none;
    max-width: 80%;
  }
}
