@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.authPageTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $primary-color;
  font-weight: bold;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 24px auto;
  text-align: center;
  @include breakpoint-up(md) {
    margin: 24px 0;
    text-align: left;
    font-size: 32px;
    line-height: 46px;
  }
  @include breakpoint-up(lg) {
    font-size: 42px;
    line-height: 56px;
  }
}

.appPageTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #333333;
  text-align: center;
  @include breakpoint-up(md) {
    font-size: 32px;
    line-height: 37px;
    text-align: left;
  }
}
