@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.donutChart {
  @include breakpoint-up(md) {
    margin-bottom: 70px;
  }
  li {
    margin-bottom: 5px;
    font-size: 15px;
  }
}
