@import 'styles/variables.scss';

.fieldWrapper {
  position: relative;
  .label {
    font-size: 16px;
    color: $primary-color;
    height: 30px;
    display: flex;
    align-items: center;
    top: -10px;
  }
  .numberInput {
    input {
      padding: 8px 0 5px 5px;
      border-bottom: 1px solid $gray-light-color;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #333;
      width: 20%;
    }
  }
  margin-bottom: 30px;
}
