.rc-pagination {
  display: flex;
  justify-content: center;
}

.rc-pagination-item-active {
  background-color: #2771bb;
  border-color: #2771bb;
}

.rc-pagination-item {
  border-radius: 6px;
}

.rc-pagination-item a {
  text-decoration: none;
  color: #666666;
}

.rc-pagination-item-active a {
  color: white;
}

.rc-pagination-jump-prev:after,
.rc-pagination-jump-next:after {
  margin-top: -27px;
  visibility: visible;
}
.rc-pagination-item:hover {
  transition: all 0.3s;
}

.rc-pagination-item-active:hover a {
  color: white;
}
