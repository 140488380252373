@import 'styles/variables.scss';

.paginationWrapper {
  margin-top: 20px;
}

.arrow {
  path {
    fill: $primary-color;
  }
}
.magnifierIcon {
  position: absolute;
  right: 0px;
  top: 22px;
}

.chevronIcon {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
  path {
    fill: $primary-color;
  }
}

.activeChevronIcon {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}
