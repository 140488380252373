@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.overviewContainer {
  @include breakpoint-up(lg) {
    margin-bottom: 20px;
  }
}

.row {
  padding-right: 12px;
}

.metric {
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  position: relative;
  &:not(:last-child) {
    @include breakpoint-up(lg) {
      .divider {
        display: inline-block;
        border-left: 1px solid $gray-light-color;
        height: 100%;
      }
    }
  }
  &:last-child .divider {
    display: none;
  }
  .title {
    color: $gray-color;
    font-size: 13.5px;
    @include breakpoint-up(lg) {
      font-size: 16px;
    }
  }
  .budgetInput {
    padding: 0;
  }
  .buttonsWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button {
      margin-left: 5px;
      margin-bottom: 5px;
    }
    @include breakpoint-up(lg) {
      flex-wrap: nowrap;
      button {
        margin-bottom: 0;
      }
    }
  }
  .value {
    font-weight: bold;
    font-size: 18px;
    line-height: 45px;
    position: relative;
    @include breakpoint-up(lg) {
      font-size: 24px;
    }
    .lock {
      position: absolute;
      top: -10px;
      right: -20px;
      fill: red;
      stroke: red;
      stroke-width: 1%;
      @include breakpoint-up(lg) {
        top: -15px;
        right: -20px;
      }
    }
  }
  .editButton {
    position: absolute;
    right: 12px;
    bottom: 5px;
    @include breakpoint-up(lg) {
      bottom: 3px;
    }
  }
}
.sprengnetter {
  color: $gray-dark-color;
  white-space: pre-line;
  cursor: default;
  margin-bottom: 16px;
  @include breakpoint-up(lg) {
    margin-bottom: 0;
  }
  .subTitle {
    color: $gray-color;
    span {
      display: inline-flex;
      svg {
        margin-left: 5px;
        width: 30%;
      }
    }
  }
  .avg {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: black;
    @include breakpoint-up(lg) {
      font-size: 27px;
      line-height: 32px;
    }
  }
  .lower {
    color: $success-color;
  }
  .upper {
    color: $yellow-color;
  }
}
.btnWrapper {
  margin-top: 10px;
  text-align: right;
  button {
    width: 100%;
    @include breakpoint-up(lg) {
      width: initial;
    }
  }
}

.chartContainer {
  text-align: center;
  svg {
    margin-left: -10px;
    padding-top: 20px;
    @include breakpoint-up(lg) {
      margin-left: 0;
    }
    text:not(:only-child) {
      &:first-child,
      &:last-child {
        display: none;
      }
    }
  }
}
