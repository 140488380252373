@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.row {
  align-items: center;
  margin-bottom: 20px;
  .firstCol {
    h1 {
      text-align: left;
    }
  }
  .secondCol {
    text-align: right;
  }
  @include breakpoint-up(md) {
    margin-bottom: 0;
  }
}
