@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.errorSelect {
  font-size: 14px;
  margin: 5px 0;
  color: #dc3545;
  text-align: left;
}
.inlineSelectLabel {
  font-size: 14px;
  color: $primary-color;
  height: 32px;
  display: flex;
  align-items: flex-end;
  top: -10px;
}
.radioGroup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  .radioItem {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px 0;
    .optionLabel {
      position: static;
      color: $primary-color;
      font-size: 15px;
    }
    .wrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
      position: relative;
      .imageLabel {
        position: static;
        cursor: pointer;
        pointer-events: initial;
        min-width: 130px;
        min-height: 95px;
        max-width: 130px;
      }
      .container {
        position: absolute;
        right: 10px;
        bottom: 0;
        @include breakpoint-up(md) {
          bottom: 12px;
        }
        .round {
          height: 28px;
          margin-right: 10px;
          label {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 50%;
            cursor: pointer;
            height: 28px;
            left: 0;
            position: absolute;
            top: 10px;
            width: 28px;
            pointer-events: initial;
            &:after {
              border: 2px solid #fff;
              border-top: none;
              border-right: none;
              content: '';
              height: 6px;
              left: 7px;
              opacity: 0;
              position: absolute;
              top: 8px;
              transform: rotate(-45deg);
              width: 12px;
            }
          }
          input[type='radio'] {
            visibility: hidden;
          }
          input[type='radio']:checked + label {
            background-color: $primary-color;
            border-color: $primary-color;
          }
          input[type='radio']:checked + label:after {
            opacity: 1;
          }
        }
      }
    }
  }
}
