@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.table {
  width: 70%;
  td,
  th {
    padding: 10px 10px;
    display: block;
    @include breakpoint-up(md) {
      display: table-cell;
    }
  }
}
.btnDeleteAccount {
  padding: 20px 0 0 10px !important;
}
