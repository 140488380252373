@import 'styles/variables.scss';

.toggleButtons {
  margin-bottom: 15px;
  .toggleButton {
    border: 1px solid rgb(175, 175, 175);
    color: rgb(175, 175, 175);
    padding: 8px 20px;
    font-weight: normal;
    background-color: white;
    border-radius: 0;
    &:not(:first-of-type) {
      margin-left: -1px;
    }
    &:first-of-type {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
    &:last-of-type {
      border-left-color: $primary-color;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
    &:hover {
      background-color: $primary-light-color;
      color: white;
    }
  }
  .activeButton {
    border-color: $primary-color;
    color: $primary-color;
    border: 1px solid $primary-color;
    // font-weight: bold;
    pointer-events: none;
    &:hover {
      background-color: white;
      box-sizing: border-box;
      color: $primary-color;
    }
  }
}
