@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.td {
  font-weight: 300;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    text-align: left;
  }
  margin: 8px 0 16px;
}

.table tr td {
  text-align: left;
}

.btnWrapper {
  margin: 10px 0;
  text-align: right;
  display: flex;
  flex-direction: column;
  button {
    width: 100%;
  }
  button:last-child {
    margin-top: 10px;
  }
  @include breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    a:last-child {
      margin-top: 0px;
      margin-left: auto;
    }
    button {
      width: initial;
      margin-left: auto;
    }
  }
}
