@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.toggleButtons {
  margin-bottom: 15px;
  display: flex;
  .toggleButton {
    border: 1px solid rgb(175, 175, 175);
    color: rgb(175, 175, 175);
    font-weight: normal;
    background-color: white;
    border-radius: 0;
    &:not(:first-of-type) {
      margin-left: -1px;
    }
    &:first-of-type {
      width: 50%;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }
    &:last-of-type {
      border-left-color: $primary-color;
      width: 50%;
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
    }
    &:hover {
      background-color: $primary-light-color;
      color: white;
    }
    padding: 3px 20px;
  }
  .activeButton {
    border-color: $primary-color;
    color: $primary-color;
    border: 1px solid $primary-color;
    pointer-events: none;
    svg path {
      fill: $primary-color;
    }
    &:hover {
      background-color: white;
      box-sizing: border-box;
      color: $primary-color;
    }
  }
}
