@import 'styles/variables.scss';

.checkboxWrapper {
  padding: 20px 0;
  .checkboxContent {
    display: flex;
    margin-bottom: 5px;
    align-items: center !important;
    .checkboxInput {
      margin-bottom: -1px;
      & * {
        box-sizing: content-box !important;
      }
    }    
    .label {
      cursor: pointer;
      padding: 0 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #5f5f5f;
      display: flex;
      align-items: center;
      .icon {
        margin-left: 5px;
        margin-top: 5px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .note {
    text-align: left;
  }
}

