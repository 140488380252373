@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.downloads {
  background-color: $whisper-grey;
  padding: 24px 16px;
  border-radius: 10px;
  text-align: center;
  h3 {
    color: $secondary-color;
  }
  .downloadButton {
    button {
      width: 100%;
    }
    @include breakpoint-up(lg) {
      button {
        width: initial;
        // &:first-of-type {
        //   margin-right: 16px;
        // }
      }
    }
    @include breakpoint-up(lg) {
      button {
        width: 100%;
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
    &:first-of-type button {
      margin-bottom: 12px;
    }
  }
}

