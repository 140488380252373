@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.sprengnetter {
  margin: 2rem 0;
  border: 1px solid $primary-color;
  padding: 16px;
  border-radius: 6px;
  text-align: left;
  p {
    color: $primary-color;
    font-size: 1.3rem;
  }
  b {
    font-size: 1.4rem;
  }
  .note {
    font-size: 1rem;
  }
}

.btnWrapper {
  text-align: right;
  button {
    width: 100%;
    @include breakpoint-up(md) {
      width: initial;
    }
  }
}
