@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.steps {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @include breakpoint-up(lg) {
    margin-top: 0;
  }
  .step {
    margin-bottom: 16px;
    .header {
      background-color: $whisper-grey;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 14px;
      .notCompletedIcon rect {
        fill: $gray-color;
      }
      .headerItem {
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
        h3 {
          margin: 0;
          font-weight: bold;
        }
      }
    }
    .body {
      margin-left: 20px;
      margin-right: 14px;
      @include breakpoint-up(md) {
        margin-left: 60px;
      }
      .description {
        margin-top: 8px;
      }
      .disabledLink {
        pointer-events: none;
      }
      a[disabled] {
        pointer-events: none;
      }
      .buttonsWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        & > span:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}