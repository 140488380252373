@import 'styles/breakpoints.scss';
@import 'styles/variables.scss';

p {
  margin-bottom: 1em;
}

ul,
ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

h1 {
  word-break: break-word;
  font-size: 1.4em;
  font-weight: 500;
  @include breakpoint-up(md) {
    font-size: 2em;
  }
}
h2 {
  word-break: break-word;
  font-size: 1.2em;
  @include breakpoint-up(md) {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000;
  }
}
h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  margin: 16px 0px;
}
h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
p {
  font-size: 14px;
  @include breakpoint-up(md) {
    font-size: 16px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.link {
  font-weight: 400;
  color: $primary-color;
  background-color: transparent;
  padding: 0.375em 0;
  font-size: 1em;
  text-decoration: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
}

.sticky-img-box {
  position: sticky;
  top: 150px;
  margin: 20px 0;
}

.card {
  position: relative;
  border: 1px solid $white-smoke-color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: 10px;
  @include breakpoint-up(md) {
    padding: 20px;
  }
}

input {
  border: none;
  width: 100%;
  padding-bottom: 10px;
  outline: none;
  font-size: 18px;
  padding-left: 0;
  background: transparent;
  font-family: $font-family;
  border-radius: 0;
  box-shadow: none;
  font-weight: 500;
  line-height: 21px;
  color: #333;
}

.default-input {
  border-bottom: 1px solid $gray-light-color;
  padding-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333;
}

.default-input:focus {
  // border-bottom: 1px solid #f8b01b;
  border-bottom: 1px solid $primary-color;
  outline: none;
}

label {
  font-size: 16px;
  color: $gray-color;
  display: block;
  cursor: auto;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}

a {
  color: $primary-color;
}

.muted {
  color: $gray-color;
}

.field-wrapper {
  position: relative;
  padding-bottom: 20px;
  padding-top: 25px;
  margin-bottom: 5px;
  width: 100%;
  label {
    position: absolute;
    transition: all 0.2s linear;
    left: 0px;
    font-size: 16px;
    pointer-events: none;
    height: 32px;
    display: flex;
    align-items: flex-end;
    top: 16px;
  }
  .label-when-focused-input,
  input:focus + label {
    color: $primary-color;
    height: 32px;
    display: flex;
    align-items: flex-end;
    top: -5px;
  }
}

.field-with-large-label {
  padding-top: 40px;
  label {
    top: 40px;
  }
  input:focus + label {
    top: 10px;
  }
  .label-when-focused-input,
  input:focus + label {
    top: 10px;
  }
}

.error-input {
  border-bottom: 1px solid #dc3545;
}

.disabled-input {
  cursor: not-allowed;
}

.error-text {
  font-size: 13px;
  margin-top: 5px;
  color: #dc3545;
  position: absolute;
}

.error-wrapper {
  padding: 20px;
  p {
    font-size: 15px;
    position: static;
  }
}

.datepicker-wrapper {
  margin-top: 10px;
  border-bottom: 1px solid #adadad;
}
.react-datepicker-wrapper {
  display: block;
}

// rc-checkbox

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: $primary-color;
  background-color: $text-inverted-color;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: $primary-color;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: $primary-color;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
  border: 2px solid $primary-color;
  border-top: 0;
  border-left: 0;
}

.rc-slider-mark {
  margin-top: 13px;
  margin-left: 2px;
  width: 99%;
}

.rec {
  & > button {
    padding: 0;
    box-shadow: none;
    background-color: $gray-light-color;
  }
}

.rec-dot_active {
  background-color: $primary-color !important;
}

.suggestion-item {
  padding: 10px 5px;
  cursor: pointer;
}

.suggestion-item--active {
  padding: 10px 5px;
  background-color: #e9f3fb;
  cursor: pointer;
}

.rc-tooltip {
  max-width: 330px;
  background-color: transparent;
}

.rc-tooltip-inner {
  border-radius: 12px;
  border-color: $primary-color;
}

.rc-tooltip-arrow {
  border-top-color: $primary-color !important;
}
.slick-dots li button:before {
  color: $gray-color;
  font-size: 10px;
}
.slick-dots li.slick-active button:before {
  color: $primary-color;
}

.rc-pagination-next,
.rc-pagination-prev {
  margin-top: 3px;
}
