@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.btnWrapper {
  display: flex;
  margin-top: 15px;
  button {
    flex: 1;
  }
  @include breakpoint-up(md) {
    justify-content: flex-end;
    button {
      flex: initial;
    }
  }
}

.labelTextarea {
  top: 43px !important;
  @include breakpoint-up(md) {
    top: 30px !important;
  }
}
