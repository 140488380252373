@import 'styles/variables.scss';

.switchWrapper {
  display: flex;
  align-items: center;
  top: 8px;
}
.switch {
  margin-right: 8px;
}
.label {
  color: $gray-color;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-size: 16px;
  position: static !important;
  height: auto !important;
  order: 1;
}
