@import "styles/variables.scss";

// text styles
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-primary {
  color: $primary-color;
}

.text-success {
  color: $success-color;
}

.text-gray {
  color: $gray-color;
}

.bold {
  font-weight: bold;
}

// margins

.ml-auto {
  margin-left: auto;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: ($spacer * 1) !important;
}

.ml-1 {
  margin-left: ($spacer * 0.25) !important;
}

.ml-4 {
  margin-left: ($spacer * 1) !important;
}

.mt-4 {
  margin-top: ($spacer * 1) !important;
}

.mt-3 {
  margin-top: ($spacer * 0.75) !important;
}

.mt-8 {
  margin-top: ($spacer * 2) !important;
}

.my-4 {
  margin-top: $spacer !important;
  margin-bottom: $spacer !important;
}

.mr-2 {
  margin-right: ($spacer * 0.5) !important;
}

// paddings

.p-3 {
  padding: ($spacer * 0.75)  !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.w-100 {
  width: 100%;
}

.bg-success {
  background-color: $success-color;
}

.cursor-default {
  cursor: default !important;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.float-right {
  float: right;
}

.disabled-link {
  pointer-events: none;
}
