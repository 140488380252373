@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  background-color: $background-color;
  color: $gray-dark-color;
  display: flex;
  font-size: 12px;
  line-height: 14px;
  height: $footer-height;
  line-height: $footer-height;
}
