@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.sidebarOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: $z-index-sidebar-overlay;
  left: 0;
  top: 0;
  animation: fadeIn ease-in-out 0.3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar {
  display: block;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: $navbar-height;
  padding-bottom: 15px;
  height: 100%;
  width: $sidebar-width;
  z-index: $z-index-sidebar;
  transform: translateX(-$sidebar-width);
  transition: transform 0.3s ease-in-out;
  box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.05);
  &.sidebarOpen {
    transform: translateX(0);
  }
  @include breakpoint-up(md) {
    transform: translateX(0);
    padding-bottom: $footer-height;
  }
}

.sidebarContent {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.sidebarMenu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebarMenuItem {
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: $text-color;
  background-color: transparent;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 200px;
  margin: 10px auto;
  text-align: left;
  border-radius: 8px;
  &:hover {
    background-color: rgba($primary-color, 0.1);
  }
  &:focus {
    outline: none;
  }
}

.greenSidebarMenuItem {
  @extend .sidebarMenuItem;
  svg path {
    fill: $success-color;
  }
  &:hover {
    background-color: rgba($success-color, 0.1);
  }
}

.sidebarMenuItemActive {
  background-color: $primary-color;
  color: $text-inverted-color !important;
  &:after {
    position: absolute;
    content: ' ';
    height: 100%;
    left: 0;
    top: 0;
  }
  & path {
    fill: $yellow-color;
  }
  &:hover {
    background-color: $primary-color;
  }
  color: $secondary-color;
}

.greenSidebarMenuItemActive {
  @extend .sidebarMenuItemActive;
  background-color: $success-color;
  svg path {
    fill: $yellow-color;
  }
  &:hover {
    background-color: $success-color;
  }
}

.sidebarMenuItemIcon {
  font-size: 1.4em;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.mainMenu {
  flex: 1 0 auto;
}
