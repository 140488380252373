@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.personalContribution {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  & div:last-child {
    padding-left: 30px;
  }
}
.disclaimer {
  margin-top: 24px;
}
.td {
  font-weight: 300;
}
.actionBar {
  display: flex;
  justify-content: flex-end;
  & :not(:last-child) {
    margin-right: 5px;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 24px 0;
  a,
  button {
    width: 100%;
  }
  button {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  @include breakpoint-up(md) {
    a,
    button {
      width: initial;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}

.totalCosts {
  h2 {
    margin: 0;
  }
}

.greenBackground {
  background-color: $success-color;
  h2 {
    color: white;
  }
}
