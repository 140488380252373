@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  text-align: center;
  margin-bottom: 15%;
  .subtitle {
    font-size: 20px;
  }
  .description {
    font-size: 1rem;
    line-height: 18px;
    color: #808080;
  }
  @include breakpoint-up(md) {
    text-align: left;
    margin-bottom: 5%;
  }
}

.projectCard {
  margin: 1rem 0;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  svg {
    max-width: 200px;
    max-height: 200px;
  }
  .btnWrapper {
    width: 100%;
    button {
      min-width: 160px;
    }
  }
  h3 {
    font-weight: bold;
  }
  @include breakpoint-up(md) {
    .subtitle {
      font-size: 24px;
    }
    .btnWrapper {
      text-align: center;
      button {
        flex: 0;
      }
    }
  }
}

.submitBtn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
