@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.btnWrapper {
  text-align: right;
}

// .labelTextarea {
  // top: 100px !important;
  // @include breakpoint-up(md) {
  //   top: 50px !important;
  // }
// }

// .textAreaFieldWrapper {
//   padding-top: 55px !important;
// }