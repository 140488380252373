@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.dateOfBirth {
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  color: $primary-color;
  margin-bottom: -2px;
  pointer-events: none;
}

.birthWrapper {
  margin-bottom: 25px;
  @include breakpoint-up(md) {
    margin-bottom: 5px;
  }
  .numberInputWrapper {
    padding-bottom: 50px;
  }
}

.errorText {
  font-size: 13px;
  margin-top: -40px;
  color: #dc3545;
  position: absolute;
}
