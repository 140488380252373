@import "styles/variables.scss";

.monthlyExpensesTable {
  tbody tr td {
    padding-left: 20px;
  }

  tbody:first-child {
    tr {
      &:not(:last-child) {
        td:first-child {
          position: relative;

          &::before {
            content: "+";
            font-size: 20px;
            position: absolute;
            bottom: -8px;
            left: 0;
          }
        }
      }

      &:last-child {
        border-bottom: 1px solid $gray-color;
      }
    }
  }

  tbody:last-child {
    tr {
      &:first-child {
        font-size: 18px;
        td:first-child {
          position: relative;
          &::before {
            content: "=";
            font-size: 20px;
            position: absolute;
            top: 8px;
            left: 0;
          }
        }
      }

      &:not(:first-child):not(:last-child) td {
        padding-left: 42px;

        &:first-child {
          position: relative;
          &::before {
            content: "–";
            font-size: 20px;
            position: absolute;
            top: 6px;
            left: 20px;
          }
        }
      }

      &:last-child {
        border-top: 1px solid $gray-color;
        font-size: 18px;

        td:first-child {
          position: relative;
          &::before {
            content: "=";
            font-size: 20px;
            position: absolute;
            top: 6px;
            left: 0;
          }
        }
      }
    }
  }
}

.buttonsWrapper {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
