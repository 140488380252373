@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  width: 100%;
  .messageField {
    width: 100%;
  }
  .buttonContainer {
    button {
      width: 100%;
    }
    @include breakpoint-up(md) {
      text-align: right;
      button {
        width: initial;
      }
    }
  }
}
