@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.enuPartner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  img {
    max-width: 200px;
  }
  .secondCol {
    text-align: center;
  }
  @include breakpoint-up(md) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    img {
      margin-right: 20px;
    }
    .secondCol {
      text-align: left;
    }
  }
}