@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 20px);
  padding: 3%;
  text-align: center;
  .title {
    text-align: center;
    text-transform: uppercase;
  }
  .message {
    margin: 6px 0 18px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $gray-dark-color;
  }
  .btnContainer {
    margin: 30px 0;
    & > button {
      width: 100%;
      &:first-child {
        margin-bottom: 24px;
      }
      @include breakpoint-up(md) {
        width: initial;
        &:first-child {
          margin-right: 24px;
          margin-bottom: 0;
        }
      }
    }
  }
  .emailConfirmedMessage {
    margin-bottom: 15px;
  }
}
