@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.iconButton {
  padding: 8px;
  font-size: 14px;
  background-color: #fff;
  color: $primary-color;
  cursor: pointer;
  transition-duration: 0.3s;
  border-width: initial;
  border-style: none;
  border-image: initial;
  outline: 0px;
  border-radius: 6px;
  font-weight: normal;
  position: relative;
  box-shadow: none;
  max-width: 40px;
  max-height: 40px;
  border: 1px solid $primary-color;
  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
  &:hover:not(:disabled) {
    background-color: rgba($primary-color, 0.1);
  }
  &:focus {
    outline: none;
  }
}

.btnContent {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    padding-bottom: 2px;
  }
}

.btnLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outline {
  background-color: #fff;
  color: $primary-color;
  svg path {
    fill: $primary-color;
  }
}

.primary {
  background-color: $primary-color;
  color: white;
  svg path {
    fill: $yellow-color;
  }
  &:hover:not(:disabled) {
    background-color: lighten($primary-color, 10%);
  }
}

.outlineSuccess {
  border: 1px solid $success-color;
  svg path {
    fill: $success-color;
  }
}

.primarySuccess {
  border: 1px solid $success-color;
  background-color: $success-color;
  svg path {
    fill: $yellow-color;
  }
  &:hover:not(:disabled) {
    background-color: lighten($success-color, 10%);
  }
}
