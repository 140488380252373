@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

$gap: 8px;
$half-gap: $gap / 2;
$gap-one-point-five-ratio: $gap / 1.5;

.button {
  padding: 10px;
  font-weight: normal;
  font-size: 13px;
  margin-top: 10px;
  min-width: 80px;
  @include breakpoint-up(md) {
    min-width: 95px;
  }
}

.notSelected {
  background-color: $text-inverted-color;
  color: $gray-color;
  border: 1px solid $gray-light-color;
  &:hover:not(:disabled) {
    border: 1px solid $primary-color;
    color: $gray-color;
    background-color: white;
  }
}

.greenNotSelected {
  background-color: $text-inverted-color;
  color: $gray-color;
  border: 1px solid $gray-light-color;
  &:hover:not(:disabled) {
    border: 1px solid $success-color;
    color: $gray-color;
    background-color: white;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $gap;
  button {
    flex: 1;
  }
}

.multiLineButtons {
  display: flex;
  flex-wrap: wrap;
  button {
    min-width: 32%;
    max-width: calc(100% / 2 - #{$half-gap});
    flex: 1;
    @include breakpoint-up(md) {
      max-width: calc(100% / 3 - #{$gap-one-point-five-ratio});
    }
    &:not(:last-child) {
      margin-right: 0;
    }
  }
}

.btnContentInColumn {
  min-width: 125px;
  // max-width: 125px;
  min-height: 115px;
  max-height: 115px;
}

.btnWithStartIcon {
  min-width: 32%;
  max-width: 50%;
  @include breakpoint-up(md) {
    max-width: 32%;
  }
}

.selected {
  background-color: $whisper-grey;
  border: 1px solid $primary-color;
  svg[class*='fill'] {
    path,
    line,
    circle {
      fill: $primary-color;
    }
  }
  svg[class*='stroke'] {
    path,
    line,
    circle,
    rect {
      stroke: $primary-color;
    }
  }
  &:hover:not(:disabled) {
    color: $primary-color;
    background-color: $text-inverted-color;
  }
}

.greenSelected {
  background-color: lighten($success-color, 53);
  border: 1px solid $success-color;
  svg[class*='fill'] {
    path,
    line,
    circle {
      fill: $success-color;
    }
  }
  svg[class*='stroke'] {
    path,
    line,
    circle,
    rect {
      stroke: $success-color;
    }
  }
  &:hover:not(:disabled) {
    color: $success-color;
    background-color: $text-inverted-color;
  }
}

.note {
  margin-top: 24px;
  white-space: pre-line;
  text-align: left;
}
