@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.sectionWrapper {
  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.header {
  background-color: $gray-light-color;
  border-radius: 6px;
  padding: 16px;
  text-transform: uppercase;
  margin-bottom: 16px;
  h3, h2 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    @include breakpoint-up(md) {
      font-size: 18px;
    }
  }
  small {
    color: black;
  }
  .endIcon {
    margin-left: auto;
    display: flex;
  }
}