@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.budgetAndFunds {
  text-align: left;
  tbody tr {
    td {
      font-size: 16px;
      font-weight: normal;
      &:first-child {
        padding-left: 22px;
      }
      &:last-child {
        padding-right: 0;
      }
      &:last-child {
        font-size: 18px;
      }
    }
    &:first-child td {
      padding-bottom: 0;
      position: relative;
      &:first-child::before {
        content: "+";
        font-size: 20px;
        position: absolute;
        bottom: -14px;
        left: 0;
      }
    }
    &:last-child {
      border-top: 1px solid $gray-color;
      td {
        font-weight: bold;
        position: relative;
        font-size: 18px;
        &:first-child::before {
          content: "=";
          position: absolute;
          left: 0px;
          top: 8px;
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}
.possibleProjectCosts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    position: relative;
    &::before {
      content: "=";
      position: absolute;
      left: -20px;
      bottom: 1px;
      font-weight: 500;
    }
  }
  b {
    font-size: 20px;
  }
}
.fieldsWrapper {
  padding-left: 20px;
  position: relative;
  div input + label {
    color: black !important;
    &::before {
      content: "–";
      position: absolute;
      left: -20px;
    }
  }
  &::before {
    content: "–";
    position: absolute;
    left: 0;
  }
  .additionalCostsLabel {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
  }
  .group {
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 18px 0;
      .label {
        position: relative;
        margin: 10px 0;
        &::before {
          content: "–";
          position: absolute;
          left: -20px;
        }
      }
      .value {
        margin: 10px 0;
        font-size: 18px;
      }
    }
  }  
}

.backButtonWrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
  .backButton {
    border: none;
    padding-left: 0;
    &:hover:not(:disabled) {
      background-color: transparent;
      color: $primary-color;
      text-decoration: underline;
    }
  }
}

.buttonsWrapper {
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: right;
  display: flex;
  flex-direction: column;
  button {
    width: 100%;
    &:first-child {
      margin-bottom: 16px;
    }
  }
  @include breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    button {
      width: initial;
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
  @include breakpoint-up(lg) {
    flex-direction: column;
    a {
      width: 100%;
    }
    button {
      width: 100%;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  @include breakpoint-up(xl) {
    flex-direction: row;
    a {
      width: initial;
    }
    button {
      width: initial;
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
}

.iconWrapper {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid $primary-color;
  margin: 16px auto 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 200px;
    height: 200px;
  }
}
