@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

@mixin operation($content, $top) {
  position: relative;
  &::before {
    content: $content;
    display: block;
    position: absolute;
    left: -16px;
    top: $top;
    font-size: 20px;
  }
}

.plus {
  @include operation('+', 6px);
}

.minus {
  @include operation('–', 6px);
}

.isEqualTo {
  @include operation('=', 6px);
}

.wrapper {
  border-radius: 8px;
  padding: 16px 24px;
}

.container {
  align-items: center;
  .tableWrapper {
    height: 100%;
    @extend .wrapper;
    @include breakpoint-down(xl) {
      margin-bottom: 16px;
    }
    border: 1px solid $primary-color;
    color: black;
    .secondStepTable {
      tr {
        td {
          padding-left: 0;
          padding-right: 0;
        }
        .additionalCostsTd {
          @extend .plus;
          font-size: 16px;
          white-space: normal;
          word-break: break-all;
          table tbody tr {
            text-align: left;
            td:last-child {
              padding-left: 8px;
            }
          }
          .trigger {
            cursor: pointer;
            margin: 0;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg {
              margin-left: 8px;
              width: 14px;
              height: 14px;
              path {
                fill: $primary-color;
              }
            }
            .chevronSvg {
              transform: rotate(90deg);
              transition: transform 0.3s ease-in;
            }
            .activeChevronSvg {
              transform: rotate(-90deg);
              transition: transform 0.3s ease-out;
            }
          }
        }
      }
    }
  }
}

.fgContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  font-size: 14px;
  @include breakpoint-up(md) {
    gap: 16px;
  }
  button {
    width: 100%;
  }
  > :first-child {
    flex: 1;
    display: flex;
    align-items: center;
  }
  > :last-child {
    flex: 1.5;
    display: flex;
    align-items: center;
  }
}

.ctaButtonsRow {
  margin-top: 24px;
  button {
    width: 100%;
  }
}

.boxesWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 16px;
  @include breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
  @include breakpoint-up(xl) {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
  }
  .primaryBox {
    width: 100%;
    grid-column: span 2;
    // flex: 1;
    border-radius: 8px;
    background-color: $primary-color;
    color: white;
    padding: 14px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    p {
      margin-top: 0;
      margin-bottom: 12px;
      font-size: 15px;
    }
    p,
    b {
      color: white;
    }
  }
  .highlightedPrimaryBox {
    p,
    b {
      font-weight: bold;
    }
    p {
      font-size: 16px;
    }
  }
}

.buttonsWrapper {
  margin-top: 16px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.finalModal {
  text-align: center;
  .imgWrapper {
    position: relative;
    .title {
      bottom: 30px;
      font-size: 14px;
      color: white;
      z-index: 2;
      text-align: center;
      position: absolute;
      text-shadow: 1px 1px 2px black;
      left: 50%;
      transform: translateX(-50%);
      @include breakpoint-up(md) {
        font-size: 24px;
      }
    }
    .wave {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      margin-top: 0;
      width: 100%;
    }
  }
  .message {
    font-weight: bold;
    margin: 0;
    font-size: 14px;
    white-space: pre-line;
    @include breakpoint-up(md) {
      font-size: 20px;
    }
  }
}

.additionalOwnFunds {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
