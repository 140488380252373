@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.disclaimer {
  position: sticky;
  top: 80px;
  z-index: 3;
  padding: 10px 20px;
  background-color: $whisper-grey;
  a,
  button {
    width: 100%;
  }
  .disclaimerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      .infoIcon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
      }
    }
    @include breakpoint-up(md) {
      .info {
        flex-direction: row;
      }
      .infoIcon {
        margin-right: 10px;
      }
    }
    @include breakpoint-up(lg) {
      flex-direction: row;
    }
    .btnWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      & a:last-child {
        margin-top: 10px;
      }
      @include breakpoint-up(md) {
        width: initial;
        flex-wrap: nowrap;
        align-items: center;
        white-space: pre;
        margin-left: 20px;
        & a:last-child {
          margin-top: 0;
          margin-left: 10px;
        }
      }
    }
  }
}
