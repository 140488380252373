@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.sprengnetterResultWrapper {
  border: 1px solid $primary-color;
  border-radius: 6px;
  padding: 16px;
  text-align: left;
  .sprengnetter {
    color: $gray-dark-color;
    white-space: pre-line;
    cursor: default;
    margin-bottom: 16px;
    @include breakpoint-up(md) {
      margin-bottom: 0;
    }
    .subTitle {
      color: $gray-color;
      span {
        display: inline-flex;
        svg {
          margin-left: 5px;
          width: 30%;
        }
      }
    }
    .avg {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: black;
      @include breakpoint-up(md) {
        font-size: 27px;
        line-height: 32px;
      }
    }
    .lower {
      color: $success-color;
    }
    .upper {
      color: $yellow-color;
    }
  }
}

.chartContainer {
  text-align: center;
  svg {
    margin-left: -10px;
    padding-top: 20px;
    @include breakpoint-up(md) {
      margin-left: 0;
    }
    text:not(:only-child) {
      &:first-child,
      &:last-child {
        display: none;
      }
    }
  }
}

.btnWrapper {
  text-align: right;
  button {
    width: 100%;
    @include breakpoint-up(md) {
      width: initial;
    }
  }
}
