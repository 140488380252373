@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.btnWrapper {
  text-align: right;
  button {
    width: 100%;
  }
  @include breakpoint-up(md) {
    button {
      width: initial;
    }
  }
}
.totalLivingSpace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: $whisper-grey;
  border-radius: 8px;
  height: 48px;
  padding: 15px;
  span {
    font-size: 16px;
    line-height: 19px;
    color: $gray-dark-color;
  }
  b {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000;
  }
}
