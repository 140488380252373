@import 'styles/variables.scss';

.radioGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  .radioItem {
    &:first-of-type {
      label {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
    }
    &:last-of-type {
      label {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-left: 1px solid $primary-light-color;
        margin-left: -1px;
      }
    }
    label {
      background-color: white;
      border: 1px solid rgb(175, 175, 175);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px 25px;
      pointer-events: initial;
      position: static;
      color: rgb(175, 175, 175);
      font-weight: normal;
      border-radius: 0;
      &:not(:first-of-type) {
        margin-left: -1px;
      }
      &:hover {
        background-color: $primary-light-color;
        color: white;
        border-color: $primary-color;
      }
    }
    input[type='radio'] {
      display: none;

      &:checked + label {
        border-color: $primary-color;
        color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
          background-color: white;
          box-sizing: border-box;
          color: $primary-color;
        }
      }
    }
  }
}
