@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.warningMessage {
  margin-top: 20px;
  .title {
    margin: 0 auto;
    text-align: center;
  }
  @include breakpoint-up(md) {
    margin-top: 5%;
  }
  .wrapper {
    margin-top: 50px;
    .message {
      font-size: 20px;
      text-align: center;
      color: $gray-color;
      @include breakpoint-up(md) {
        font-size: 32px;
      }
    }
    button {
      width: 100%;
    }
    @include breakpoint-up(md) {
      button {
        width: initial;
      }
    }
  }
}
