@import 'styles/variables.scss';

.list {
  list-style: none;
}
.listItem {
  margin: 12px 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #333;
  &::before {
    content: '\2022';
    display: inline-block;
    color: $primary-color;
    width: 16px;
    margin-left: -16px;
  }
}
