@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  h1 {
    margin-top: 0;
  }
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #5f5f5f;
}

.checkboxesLabel {
  font-size: 16px;
  color: $primary-color;
  height: 16px;
  display: flex;
  align-items: center;
}

.contactByCheckboxesWrapper {
  display: flex;
  margin-bottom: 10px;
  div {
    &:first-child {
      padding-left: 0;
    }
    padding-left: 10px;
    padding-right: 10px;
  }
}

.reachabilityOnPhoneLabel {
  font-size: 16px;
  color: $primary-color;
  height: 15px;
  display: flex;
  align-items: center;
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  white-space: pre;
  @include breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
  }
  .group {
    display: flex;
    align-items: center;
  }
  .reachabilityInput {
    padding-bottom: 0px;
    color: #5f5f5f;
  }
}

.fieldWrapper {
  min-width: 50px;
  width: 17% !important;
  padding: 5px 10px !important;
  min-width: 75px;
  @include breakpoint-up(md) {
    width: 6% !important;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  button {
    width: 100%;
  }
  @include breakpoint-up(md) {
    text-align: right;
    button {
      width: initial;
    }
  }
}

.additionalInformation {
  margin-top: 45px;
}
