@import 'styles/variables.scss';

.triggeredContent {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid silver;
  cursor: pointer;
}

.menu {
  width: 290px;
  height: 360px;
  background-color: white;
  border-radius: 16px;
  list-style-type: none;
  box-shadow: 6px 6px 48px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 32px;
  box-sizing: border-box;
  outline: none;
  .img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: 0 auto 16px;
  }
  .name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 8px;
  }
  .email {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: $gray-color;
    margin-bottom: 24px;
  }
  .divider {
    width: 200px;
    margin: 0 auto;
    border-top: 1px solid $whisper-grey;
    border-left: 1px solid $whisper-grey;
  }
  .btn {
    button {
      margin-bottom: 12px;
      width: 200px;
      height: 40px;
      border: 1px solid $gray-light-color;
      padding: 5px;
      box-shadow: none;
    }
  }
  .logoutBtn {
    @extend .btn;
    button {
      &:hover {
        path {
          fill: white;
        }
      }
    }
  }
  .btnDeleteAccount {
    @extend .btn;
    button {
      color: red;
      border: none;
      &:hover {
        background-color: white;
        color: red;
      }
    }
  }
}
