@import 'styles/variables';
@import 'styles/breakpoints.scss';

.mapContainer {
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  @include breakpoint-up(md) {
    margin: 0;
  }
  .toggleButtons {
    margin: auto;
    display: flex;
    justify-content: center;
    order: -1;
    @include breakpoint-up(md) {
      width: 80%;
      margin: 25px auto 0;
      order: 0;
    }
    .activeButton {
      align-items: center;
      & path {
        fill: $primary-color;
        stroke: $primary-color;
      }
    }
  }

  .buttonLabel {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }

  .buttonText {
    margin: auto;
  }
  .map {
    position: static !important;
  }
}
