@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  box-sizing: border-box;
  flex-wrap: wrap;
  margin: 15px 0;
  & :first-child > input {
    margin-left: 0px;
  }
}
.input {
  min-width: 33px;
  min-height: 33px;
  width: 2.2rem !important;
  height: 2.2rem;
  border-radius: 15%;
  margin: 3px;
  padding: 0;
  background-color: #ebedef;

  @include breakpoint-up(md) {
    width: 40px !important;
    height: 40px;
    margin: 8px;
  }
}
.focusInput {
  border: 1px solid #0072bb;
}
