.container {
  margin-top: 10% !important;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  & > button {
    padding: 1em 0.8em;
    margin: 0 10px;
  }
}
