@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.green-tabs {
  button[class*='activeTab'] {
    border-color: $success-color;
    div[class*='circle'] {
      border-color: $success-color;
    }
  }
  button[class*='completedTab'] {
    border-color: $success-color;
  }
}

.tabs {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  position: sticky;
  flex-wrap: wrap;
  top: 70px;
  background: white;
  z-index: 1;
  // padding: 0 5px;
  @include breakpoint-up(md) {
    margin-bottom: 20px;
  }
  .tab {
    flex-grow: 1;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    font-size: 14px;
    //reset standard button styles
    border-width: initial;
    border-style: none;
    border-image: initial;
    outline: 0px;
    background-color: white;
    padding: 15px 0;
    @include breakpoint-up(md) {
      padding: 15px 5px;
      border-bottom: 2px solid $gray-light-color;
    }
    @include breakpoint-up(lg) {
      padding: 15px 3px;
      font-size: 13px;
    }
    @include breakpoint-up(xl) {
      padding: 15px 5px;
      font-size: 14px;
    }
    .circle {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8px;
      height: 8px;
      border-radius: 50px;
      background-color: $gray-light-color;
      .step {
        display: none;
      }
      @include breakpoint-up(md) {
        width: 24px;
        height: 24px;
        border: 1px solid $gray-light-color;
        background-color: transparent;
        .step {
          display: inline;
        }
      }
      @include breakpoint-up(lg) {
        width: 22px;
        height: 22px;
      }
      @include breakpoint-up(xl) {
        width: 24px;
        height: 24px;
      }
    }
    .title {
      display: none;
      @include breakpoint-up(lg) {
        display: inline-block;
        margin-left: 3px;
        color: $gray-color;
      }
      @include breakpoint-up(xl) {
        display: inline-block;
        margin-left: 5px;
        color: $gray-color;
      }
    }
  }
  .activeTab {
    max-width: 55%;
    .circle {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border: 1px solid $primary-color;
      background-color: transparent;
      .step {
        display: block;
      }
    }
    .title {
      color: black;
      margin-left: 3px;
      display: block !important;
    }
    @include breakpoint-up(md) {
      border-bottom: 2px solid $primary-color;
      border-radius: 6px 6px 0px 0px;
    }
    @include breakpoint-up(lg) {
      .circle {
        min-width: 22px;
        width: 22px;
        min-height: 22px;
        height: 22px;
      }
    }
    @include breakpoint-up(xl) {
      .circle {
        min-width: 24px;
        width: 24px;
        min-height: 24px;
        height: 24px;
      }
    }
  }
  .clickableTab {
    &:hover {
      cursor: pointer !important;
    }
  }
  .completedTab {
    @include breakpoint-up(md) {
      border-bottom: 2px solid $primary-color;
      border-radius: 6px 6px 0px 0px;
    }
    .title {
      color: #808080;
    }
    .circle {
      background-color: $success-color;
      border: none;
      @include breakpoint-up(md) {
        &:after {
          content: '';
          display: inline-block;
          width: 5px;
          height: 10px;
          transform: rotate(45deg);
          border-right: 2px solid white;
          border-bottom: 2px solid white;
          margin-bottom: 3px;
        }
      }
      .step {
        display: none;
      }
    }
  }
  button.completedTab.activeTab {
    .title {
      font-weight: bold;
    }
    .circle {
      &::after {
        content: '';
        display: inline-block;
        width: 5px;
        height: 10px;
        transform: rotate(45deg);
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        margin-bottom: 3px;
      }
    }
  }
}
