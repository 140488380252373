.subItem {
  .header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    .title {
      margin: 0;
    }
  }
  .description {
    margin: 16px 0;
  }
}
