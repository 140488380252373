.description {
  color: #a6a6a6;
  font-size: 13px;
  margin-bottom: 1.3em;
  min-height: 13px;
  text-align: left;
}
.heading {
  color: #5f5f5f;
  margin-bottom: -35px;
  text-align: left;
}
