.category {
  .title {
    margin: 0;
  }
  .description {
    margin-bottom: 20px;
  }
  .price {
    margin: 0;
    white-space: nowrap;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
