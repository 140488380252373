@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.buttonsWrapper {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & button:only-child {
    flex-grow: 1;
  }
  @include breakpoint-up(md) {
    & button:only-child {
      flex-grow: 0;
    }
  }
}

.signUpLink {
  background-color: transparent;
  outline: none;
  border: none;
  border-width: initial;
  border-style: none;
  border-image: initial;
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
}
