@import 'styles/variables.scss';

.projectName {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000;
  margin: 0;
}

.disabledBtn {
  & path {
    fill: #e56666;
  }
}

.projectCard {
  background: $text-inverted-color;
  border: 1px solid $gray-light-color;
  box-sizing: border-box;
  box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 25px;
  margin: 10px 0;
  position: relative;
  &:hover {
    border: 1px solid $primary-color;
    box-shadow: 12px 24px 40px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    .projectName {
      color: $primary-color;
    }
  }
  .projectInfo {
    margin: 17px 0;
    .row {
      margin-top: 10px;
      .item {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: $gray-dark-color;
      }
      .currency {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000;
      }
    }
  }
  .divider {
    border: 1px solid $gray-light-color;
    box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 17px;
  }
}

.activeCard {
  border: 2px solid $primary-color;
  &:hover {
    border: 2px solid $primary-color;
  }
}

.buttonsWrapper {
  a, button {
    width: 100%;
  }
  & > :not(:last-child) button {
    margin-bottom: 10px;
  }
}
