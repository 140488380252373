@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.withoutGrid {
  thead tr th,
  tbody tr td {
    border: none;
  }
  th:empty {
    display: none;
  }
  .actualEnergyClass {
    height: 40px;
    width: 75%;
    .triangle {
      left: -19px;
      border-right: 19px solid $primary-color;
      border-top: 20px solid transparent !important;
      border-bottom: 20px solid transparent !important;
    }
  }
}

.energyScaleTable {
  width: 100%;
  margin-top: 20px;
  td,
  th {
    &:last-child {
      border-right: none;
    }
  }
  thead tr th {
    background-color: white;
    font-size: 14px;
    padding: 5px;
    @include breakpoint-up(md) {
      font-size: 19px;
      line-height: 23px;
    }
  }
  tbody tr td {
    border-collapse: collapse;
    color: white;
    padding: 0;
    .energyClass {
      padding: 5px;
      font-size: 22px;
      font-weight: 600;
      position: relative;
      margin-top: -1px;
      .triangle {
        position: absolute;
        display: inline-block;
        right: -18px;
        top: 0;
        width: 0;
        border-left: 18px solid $primary-color;
        border-top: 22px solid transparent !important;
        border-bottom: 22px solid transparent !important;
        border-right: none;
      }
      @include breakpoint-up(lg) {
        font-size: 18px;
        .triangle {
          border-top: 19px solid transparent !important;
          border-bottom: 19px solid transparent !important;
        }
      }
      @include breakpoint-up(xl) {
        font-size: 22px;
        .triangle {
          border-top: 22px solid transparent !important;
          border-bottom: 22px solid transparent !important;
        }
      }
    }
  }
  @include breakpoint-up(md) {
    margin-top: 0;
  }
}

.houseEnergySection {
  margin-bottom: 24px;
  .houseEnergyIndicators {
    background-color: $whisper-grey;
    padding: 28px 16px;
    border-radius: 6px;
    text-align: center;
    .label {
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 0;
      font-size: 16px;
    }
    .heatingDemandValue {
      display: block;
      font-size: 24px;
      margin: 16px 0;
    }
    .btnWrapper {
      margin-top: 40px;
    }
    @include breakpoint-up(md) {
      text-align: left;
      margin-top: 16px;
      .heatingDemandValue {
        margin: 36px 0;
      }
    }
    @include breakpoint-up(lg) {
      text-align: center;
      margin-top: 0;
      .label {
        font-size: 18px;
      }
    }
    @include breakpoint-up(xl) {
      .label {
        font-size: 20px;
      }
    }
  }
}

.actualEnergyClass {
  width: 60%;
  height: 24px;
  margin: auto;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0px;
  .label {
    display: none;
  }
  .triangle {
    position: absolute;
    display: inline-block;
    left: -12px;
    width: 0;
    border-right: 12px solid $primary-color;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
  }
  @include breakpoint-up(md) {
    height: 20px;
    max-width: 100px;
    .label {
      display: block;
      text-transform: uppercase;
    }
    .triangle {
      left: -9px;
      border-right: 9px solid $primary-color;
      border-top: 10px solid transparent !important;
      border-bottom: 10px solid transparent !important;
    }
  }
}
