@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.categoryTab {
  margin-right: 5px;
  margin-bottom: 5px;
  flex-grow: 1;
  @include breakpoint-up(md) {
    flex-grow: 0;
  }
}

.primaryActiveCategoryTab {
  color: $text-inverted-color;
  background-color: $primary-color;
  &:hover {
    color: rgba($text-inverted-color, 0.9) !important;
    background-color: $primary-color !important;
  }
}

.greenActiveCategoryTab {
  color: $text-inverted-color;
  background-color: $success-color;
  &:hover {
    color: rgba($text-inverted-color, 0.9) !important;
    background-color: $success-color !important;
  }
}

.categoryTabs {
  display: flex;
  flex-wrap: wrap;
}
.container {
  margin-bottom: 60px;
}
.planOverviewBtn {
  button {
    width: 100%;
  }
  @include breakpoint-up(md) {
    text-align: right;
    button {
      width: initial;
    }
  }
}
