@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.textareaWrapper {
  position: relative;
  .textArea {
    width: 100%;
    height: 340px;
    min-height: 200px;
    background: white;
    resize: none;
    padding: 20px;
    outline: none;
    border: 1px solid $gray-light-color;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .textLabel {
    left: 0px;
    padding-left: 22px;
    height: 22px;
    padding-right: 22px;
  }
}
