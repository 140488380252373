.showMoreText {
  color: #5b5b5b;
  text-align: left;
  margin: 1.5rem 0;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}