@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.monthlyExpensesUl {
  margin: 0;
  padding: 0;
  ol {
    position: relative;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      left: 0;
      top: 3px;
      background-color: $primary-color;
    }
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}