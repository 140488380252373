@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.almostRectangle {
  background-color: $success-color;
  color: white;
  padding: 15px 20px;
  text-align: left;
  font-size: 16px;
  line-height: 25px;
  min-height: 80px;
  max-height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 90%;
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -30px;
    width: 0;
    bottom: 0;
    border-left: 30px solid $success-color;
    border-top: 40px solid transparent !important;
    border-bottom: 40px solid transparent !important;
    border-right: none;
  }
  @include breakpoint-up(lg) {
    width: 100%;
    font-size: 18px;
  }
}
.equivalent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .circle {
    background-color: $success-color;
    width: 80px;
    height: 80px;
    min-height: 80px;
    min-width: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-self: center;
    svg {
      width: 100%;
      height: 50%;
    }
  }
  .text {
    color: $primary-color;
    text-align: center;
    font-size: 14px;
  }
}