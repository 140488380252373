@import '../../styles/variables.scss';

.sliderContainer {
  height: 48px;
  padding-left: 5px;
  padding-right: 5px;
}

.slider {
  margin-top: 10px;
}
