@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.overviewCol {
  background-color: $whisper-grey;
  border-radius: 10px;
  padding: 16px 0;
  margin-bottom: 20px;
  .projectName {
    text-align: center;
    color: $secondary-color;
    margin-top: 0;
  }
  .grayItem {
    background-color: $gray-light-color;
    text-align: center;
    padding: 10px;
    margin-bottom: 16px;
    h3 {
      margin: 0 0 8px;
    }
    b {
      font-size: 20px;
    }
    .notAvailableValue {
      font-size: 14px;
      color: #333;
    }
  }
  .primaryItem {
    background-color: $primary-color;
    text-align: center;
    padding: 10px;
    margin-bottom: 16px;
    color: white;
    h3 {
      color: white;
      margin: 0 0 8px;
    }
    b {
      text-transform: uppercase;
      font-size: 18px;
    }
  }
}