@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.divider {
  width: 93%;
  margin: 15px 10px;
  height: 1px;
  background-color: #f6f2ff;
}

.budgetSummary {
  td {
    text-align: left;
  }
}

.btnWrapper {
  text-align: right;
  a, button {
    width: 100%;
  }
  @include breakpoint-up(md) {
    a, button {
      width: initial;
    } 
  }
}