@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sectionWrapper {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
