$primary-color: #2771bb;
$success-color: #3caa38;
$pastel-green-color: #a3c613;
$yellow-color: #fad504;
$red-color: #ff0000;
$primary-light-color: lighten($primary-color, 5);
$secondary-color: #003770;
$background-color: white;
$gray-color: #808080;
$gray-light-color: #e0e0e0;
$gray-dark-color: #5f5f5f;
$white-smoke-color: #f2f2f2;
$whisper-grey: #f7f7f7;
$font-family: Montserrat, sans-serif;
$sidebar-width: 240px;
$navbar-height: 72px;
$footer-height: 35px;
$text-color: black;
$text-inverted-color: white;

$spacer: 16px;

// Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$z-index-loading-overlay: 10;
$z-index-navbar: 10;
$z-index-footer: 5;
$z-index-modal: 30;
$z-index-sidebar-overlay: 7;
$z-index-sidebar: 8;

// the :export directive is the magic sauce for webpack
:export {
  primaryColor: $primary-color;
  primaryLightColor: $primary-light-color;
  secondaryColor: $secondary-color;
  successColor: $success-color;
  yellowColor: $yellow-color;
  redColor: $red-color;
  whiteSmokeColor: $white-smoke-color;
  grayColor: $gray-color;
  grayLightColor: $gray-light-color;
  grayDarkColor: $gray-dark-color;
  whisperGrey: $whisper-grey;
  navbarHeight: $navbar-height;
  pastelGreenColor: $pastel-green-color;
}
