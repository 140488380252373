.table {
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    padding: 10px 15px;
  }

  thead tr {
    background-color: $whisper-grey;
    border-radius: 6px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
  }

  thead tr,
  tfoot tr {
    th,
    td {
      border: none;
      border-spacing: unset;
      color: $text-color;
      text-align: left;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0.4em 0;
    }
  }

  &.primary {
    thead tr {
      background-color: $primary-color;
      th {
        color: $text-inverted-color;
      }
    }
  }

  tfoot tr {
    td {
      font-weight: bold;
    }
  }

  tbody {
    background-color: $background-color;
    td {
      font-weight: 300;
    }
  }

  &.last-col-text-right {
    td:last-child {
      white-space: nowrap;
      text-align: right;
    }
  }
  tr:first-child td:first-child {
    border-top-left-radius: 6px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 6px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }
}
