.monthlyIncomeTable {
  tbody {
    tr:first-child {
      td {
        padding-bottom: 0px;
      }
    }
    tr:last-child {
      td {
        padding-top: 5px;
        padding-bottom: 17px;
      }
    }
  }
}
